/*==================== GOOGLE FONTS ====================*/
@import url('https://fonts.googleapis.com/css2?family=Poppins:wght@400;500;600&display=swap');

/*==================== VARIABLES CSS ====================*/
:root {
    --header-height: 3rem;

    /*========== Colors ==========*/
    /* Change favorite color */
    --hue-color: 250; /*Purple 250 - Green 142 - Blue 230 - Pink 340*/

    /* HSL color mode */
    --first-color: hsl(0deg 0% 0%);
    --first-color-second: hsl(var(--hue-color), 69%, 61%);
    --first-color-alt: hsl(var(--hue-color), 57%, 53%);
    --first-color-lighter: hsl(var(--hue-color), 92%, 85%);
    --title-color: hsl(var(--hue-color), 8%, 15%);
    --text-color: hsl(var(--hue-color), 8%, 45%);
    --text-color-light: hsl(var(--hue-color), 8%, 65%);
    --input-color: hsl(var(--hue-color), 70%, 96%);
    --body-color: hsl(var(--hue-color), 60%, 99%);
    --container-color: #FFF ;
    --scroll-bar-color: hsl(var(--hue-color), 12%, 90%);
    --scroll-thumb-color: hsl(var(--hue-color), 85%, 90%);
    --scroll-thumb-color-active: hsl(var(--hue-color), 85%, 80%);

    /*========== Font and typography ==========*/
    --body-font: 'Poppins', sans-serif;

    /* .5rem = 8px, 1rem = 16px, 1.5rem = 24px ... */
    --big-font-size: 2rem;
    --h1-font-size: 1.5rem;
    --h2-font-size: 1.25rem;
    --h3-font-size: 1.125rem;
    --normal-font-size: .938rem;
    --small-font-size: .813rem;
    --smaller-font-size: .75rem;

    /*========== Font weight ==========*/
    --font-medium: 500;
    --font-semi-bold: 600;

    /*========== Margenes Bottom ==========*/
    /* .25rem = 4px, .5rem = 8px, .75rem = 12px ... */
    --mb-0-25: .25rem;
    --mb-0-5: .5rem;
    --mb-0-75: .75rem;
    --mb-1: 1rem;
    --mb-1-5: 1.5rem;
    --mb-2: 2rem;
    --mb-2-5: 2.5rem;
    --mb-3: 3rem;

    /*========== z index ==========*/
    --z-tooltip: 10;
    --z-fixed: 100;
    --z-modal: 1000;
}

/* Font size for large devices */
@media screen and (min-width: 968px) {
    :root {
        --big-font-size: 3rem;
        --h1-font-size: 2.25rem;
        --h2-font-size: 1.5rem;
        --h3-font-size: 1.25rem;
        --normal-font-size: 1rem;
        --small-font-size: .875rem;
        --smaller-font-size: .813rem;
    }
}

/*========== Variables Dark theme ==========*/
body.dark-theme{
        /* HSL color mode */
        --first-color-second: hsl(var(--hue-color), 30%, 8%);
        --title-color: hsl(var(--hue-color), 8%, 95%);
        --text-color: hsl(var(--hue-color), 8%, 75%);
        --input-color: hsl(var(--hue-color), 29%, 16%);
        --body-color: hsl(var(--hue-color), 28%, 12%);
        --container-color: hsl(var(--hue-color), 29%, 16%);
        --scroll-bar-color: hsl(var(--hue-color), 12%, 48%);
        --scroll-thumb-color: hsl(var(--hue-color), 90%, 82%);
        --scroll-thumb-color-active: hsl(var(--hue-color), 90%, 72%);
}

/*========== Button Dark/Light ==========*/
.nav__btns{
    display: flex;
    align-items: center;
}

.change-theme{
    font-size: 1.25rem;
    color: var(--title-color);
    margin-right: var(--mb-1);
    cursor: pointer;
}

.change-theme:hover{
    color: var(--first-color);
}

/*==================== BASE ====================*/
*{
    box-sizing: border-box;
    padding: 0;
    margin: 0;
}

html{
    scroll-behavior: smooth;
}

body{
    margin: 0 0 var(--header-height) 0;
    font-family: var(--body-font);
    font-size: var(--normal-font-size);
    background-color: var(--body-color);
    color: var(--text-color);
}

h1,h2,h3,h4{
    color: var(--title-color);
    font-weight: var(--font-semi-bold);
}

ul{
    list-style-type: none;
}

a{
    text-decoration: none;
}

img{
    max-width: 100%;
    height: auto;
}

/*==================== REUSABLE CSS CLASSES ====================*/
.section{
    padding: 2rem 0 4rem;
    /* height: 100vh; */
}

.section__title{
    font-size: var(--h1-font-size);
}

.section__subtitle{
    display: block;
    font-size: var(--small-font-size);
    margin-bottom: var(--mb-3);
}

.section__title,
.section__subtitle{
    text-align: center;
}

/*==================== LAYOUT ====================*/
.container{
    max-width: 768px;
    margin-left: var(--mb-1-5);
    margin-right: var(--mb-1-5);
}

.grid{
    display: grid;
    gap: 1.5rem;
}

.header{
    width: 100%;
    position: fixed;
    bottom: 0;
    left: 0;
    z-index: var(--z-fixed);
    background-color: var(--body-color);
    box-shadow: 0 -1px 4px rgba(0, 0, 0,.15);
}
/*==================== LOADER ====================*/
.loader__container{
    width: 100%;
    height: 100vh;
    text-align: center;
    display: flex;
    justify-content: center;
    align-items: center;
    align-content: center;    
}

/*==================== NAV ====================*/
.nav{
    max-width: 968px;
    height: var(--header-height);
    display: flex;
    justify-content: space-between;
    align-items: center;
}

.nav__logo,
.nav__toggle{
    color: var(--title-color);
    font-weight: var(--font-medium);
}

.nav__logo:hover{
    color: hsl(0deg 99% 32%);
}

.nav__toggle{
    font-size: 1.1rem;
    cursor: pointer;
}

.nav__toggle:hover{
    color: var(--first-color);
}

@media screen and (max-width: 767px){
    .nav__menu{
        position: fixed;
        bottom: 0;
        left: 0;
        width: 100%;
        background-color: var(--body-color);
        padding: 2rem 1.5rem 4rem;
        box-shadow: 0 -1px 4px rgba(0, 0, 0,.15);
        border-radius: 1.5rem 1.5rem 0 0;
        transition: .3s;
    }
}

.nav__list{
    grid-template-columns: repeat(3, 1fr);
    gap: 2rem;
}

.nav__link{
    display: flex;
    flex-direction: column;
    align-items: center;
    font-size: var(--small-font-size);
    color: var(--title-color);
    font-weight: var(--font-medium);
}

.nav__link:hover{
    color: hsl(0deg 99% 32%);
}

.nav__icon{
    font-size: 1.2rem;
}

.nav__close{
    position: absolute;
    right: 1.3rem;
    bottom: 0.5rem;
    font-size: 1.5rem;
    cursor: pointer;
    color: var(--first-color);
}

.nav__close:hover{
    color: var(--first-color-alt);
}

/* show menu */
.show-menu{
    bottom: 0 !important;
}

/* Active link */
.active-link{
    color: hsl(0deg 0% 58%);
}

/* Change background header */


/*==================== HOME ====================*/
.home__container{
    gap: 1rem;
}

.home__content{
    grid-template-columns: .5fr 3fr;
    padding-top: 3.5rem;
    align-items: center;
}

.home__social{
    display: grid;
    grid-template-columns: max-content;
    row-gap: 1rem;
}

.home__social-icon{
    font-size: 1.25rem;
    color: hsl(0deg 0% 57%);
}

.home__social-icon:hover{
    color: hsl(0deg 99% 32%);
}

.home__blob{
    width: 200px;
    fill: hsl(0deg 99% 32%);

}

.home__blob-img{
    /* width: 450px; */
    width: 200px;
}

.home__data{
    grid-column: 1/3;
}

.home__title{
    font-size: var(--big-font-size);
}

.home__subtitle{
    font-size: var(--h3-font-size);
    color: var(--text-color);
    font-weight: var(--font-medium);
    margin-bottom: var(--mb-0-5);
    height: 1.875rem;
}

.home__subtitle2{
    font-size: var(--h3-font-size);
    color: var(--text-color);
    font-weight: var(--font-medium);
    margin-bottom: var(--mb-2-5);
    height: 1.875rem;
}

.home__description{
    margin-bottom: var(--mb-2);
}

.home__scroll-button{
    color: hsl(0deg 0% 32%);
    transition: .3s;
}

.home__scroll-button:hover{
    transform: translateY(.25rem);
}

.home__social-mouse{
    font-size: 2rem;
}

.home__scroll-name{
    font-size: var(--small-font-size);
    color: var(--title-color);
    font-weight: var(--font-medium);
    margin-right: var(--mb-0-25);
}

.home__scroll-arrow{
    font-size: 1.25rem;
}

/*==================== BUTTONS ====================*/
.button{
    display: inline-block;
    background-color: var(--first-color);
    color: #fff;
    padding: 1rem;
    border-radius: .5rem;
    font-weight: var(--font-medium);
}

.button:hover{
    background-color: hsl(0deg 0% 93%);
    color: #333;
}

.button__icon{
    font-size: 1.25rem;
    margin-left: var(--mb-0-5);
    transition: .3s;
}

.button--flex{
    display: inline-flex;
    align-items: center;
}

/*==================== ABOUT ====================*/
.about__img{
    width: 400px;
    border-radius: .5rem;
    justify-self: center;
    align-self: flex-start;
    float: right;
    margin-left: var(--mb-1);
    margin-bottom: var(--mb-0-5);
}

.about__img2{
    width: 400px;
    border-radius: .5rem;
    justify-self: center;
    align-self: flex-start;
    float: left;
    margin-right: var(--mb-1);
    margin-bottom: var(--mb-0-5);
}

.about__description{
    text-align: justify;
    margin-bottom: var(--mb-2-5);
}

.about__info{
    display: flex;
    justify-content: space-evenly;
    margin-bottom: var(--mb-2-5);
}

.about__info-title{
    font-size: var(--h2-font-size);
    font-weight: var(--font-semi-bold);
    color: var(--title-color);
}

.about__info-name{
    font-size: var(--smaller-font-size);
}

.about__info-title,
.about__info-name{
    display: block;
    text-align: center;
}

.about__buttons{
    display: flex;
    justify-content: center;

}

/*==================== SKILLS ====================*/
.skills__container{
    row-gap: 0;
}

.skills__content{
    height: max-content;
    margin-bottom: var(--mb-2-5);
}

.skills__header{
    display: flex;
    align-items: center;
    margin-bottom: var(--mb-2-5);
    cursor: pointer;
}

.skills__icon,
.skills__arrow{
    font-size: 2rem;
    color: var(--first-color);
}

.skills__icon{
    margin-right: var(--mb-0-75);
}

.skills__title{
    font-size: var(--h3-font-size);
}

.skills__subtitle{
    font-size: var(--small-font-size);
    color: var(--text-color-light);
}

.skills__arrow{
    margin-left: auto;
    transition: .4s;
}

.skills__list{
    row-gap: 1.5rem;
    /* padding-left: 2.7rem; */
}

.skills__titles{
    display: flex;
    justify-content: space-between;
    margin-bottom: var(--mb-0-5);
}

.skills__name{
    font-size: var(--normal-font-size);
    font-weight: var(--font-medium);
}

.skills__bar,
.skills__percentage{
    height: 5px;
    border-radius: .25rem;
}

.skills__bar{
    background-color: var(--first-color-lighter);
}

.skills__percentage{
    display: block;
    background-color: hsl(0deg 0% 74%);
}

/* .skills__entoes{
    width: 95%;
} */

/*==================== QUALIFICATION ====================*/
.qualification__tabs{
    display: flex;
    justify-content: space-evenly;
    margin-bottom: var(--mb-2);
}

.qualification__button{
    font-size: var(--h3-font-size);
    font-weight: var(--font-medium);
    cursor: pointer;
}

.qualification__button:hover{
    color: var(--first-color);
}

.qualification__icon{
    font-size: 1.8rem;
    margin-right: var(--mb-0-25);
}

.qualification__data{
    display: grid;
    grid-template-columns: 1fr max-content 1fr;
    column-gap: 1.5rem;
}

.qualification__title{
    font-size: var(--normal-font-size);
    font-weight: var(--font-medium);
}

.qualification__subtitle{
    display: inline-block;
    font-size: var(--small-font-size);
    margin-bottom: var(--mb-1);
}

.qualification__calendar{
    font-size: var(--smaller-font-size);
    color: var(--text-color-light);
    display: flex;
    align-items: center;
}

.qualification__rounder{
    display: inline-block;
    width: 13px;
    height: 13px;
    background-color: var(--first-color);
    border-radius: 50%;
}

.qualification__line{
    display: block;
    width: 1px;
    height: 100%;
    background-color: var(--first-color);
    transform: translate(6px, -7px);
}

.qualification__content{
    display: inline-block;
    /* position: absolute; */
}

/*==================== SERVICES ====================*/


/* Active Modal */


/*==================== PORTFOLIO ====================*/
.portfolio__tabs{
    display: flex;
    justify-content: space-evenly;
    margin-bottom: var(--mb-2);
}

.portfolio__button{
    font-size: var(--h3-font-size);
    font-weight: var(--font-medium);
    cursor: pointer;
    border-radius: 5px;
    padding: var(--mb-0-5);
}

.portfolio__button:hover{
    color: var(--first-color);
}

.portfolio__sections{
    display: flex;
    flex-direction: row;
    flex-wrap: wrap;
    align-content: center;
    justify-content: space-evenly;
    align-items: center;
}

.portfolio__subcat__button{
    font-size: var(--normal-font-size);
    font-weight: var(--font-medium);
    cursor: pointer;
    border-radius: 5px;
    padding: var(--mb-0-25);
}

.portfolio__subcat__button:hover{
    color: var(--first-color);
}

/*==================== PROJECT IN MIND ====================*/


/*==================== TESTIMONIAL ====================*/


/*==================== CONTACT ME ====================*/
.contact__container{
    row-gap: 3rem;
    display: flex;
    flex-direction: column;
}

.contact__wrapper{
    display: flex;
    justify-content: space-evenly;
    align-items: center;
}

.contact__information{
    display: flex;
    margin-bottom: var(--mb-2);
}

.contact__icon{
    font-size: 2rem;
    color: hsl(0deg 0% 43%);
    margin-right: var(--mb-0-75);
    align-self: center;
}

.contact__title{
    font-size: var(--h3-font-size);
    font-weight: var(--font-medium);
}

.contact__subtitle{
    font-size: var(--small-font-size);
    color: var(--text-color-light);
}

.contact__content{
    background-color: var(--input-color);
    border-radius: .5rem;
    padding: .75rem 1rem .25rem;
}

.contact__label{
    font-size: var(--smaller-font-size);
    color: var(--title-color);
}

.contact__input{
    width: 100%;
    background-color: var(--input-color);
    color: var(--text-color);
    font-family: var(--body-font);
    font-size: var(--normal-font-size);
    border: none;
    outline: none;
    padding: .25rem .5rem .5rem 0;
}

/*==================== FOOTER ====================*/
.footer{
    padding-top: 2rem;
}

.footer_container{
    row-gap: 3.5rem;
}

.footer__bg{
    background-color: hsl(0deg 0% 0%);
    padding: 2rem 0 3rem;
}

.footer__title{
    font-size: var(--h1-font-size);
    margin-bottom: var(--mb-0-25);
}

.footer__subtitle{
    font-size: var(--small-font-size);
}

.footer__links{
    display: flex;
    flex-direction: column;
    row-gap: 1.5rem;
}

.footer__link:hover{
    color: hsl(0deg 99% 32%);
}

.footer__social{
    font-size: 1.25rem;
    margin-right: var(--mb-1-5);
}

.footer__social:hover{
    color: hsl(0deg 99% 32%);
}

.footer__copy{
    font-size: var(--small-font-size);
    text-align: center;
    color: var(--text-color-light);
    margin-top: var(--mb-3);
}

.footer__title,
.footer__subtitle,
.footer__link,
.footer__social{
    color: #FFF;
}

/*========== SCROLL UP ==========*/
.scrollup{
    position: fixed;
    right: 1rem;
    bottom: 5rem;
    background-color: hsl(0deg 0% 54%);
    opacity: .8;
    padding: 0 .3rem;
    border-radius: .4rem;
    z-index: var(--z-tooltip);
    transition: .4s;
    border-color: hsl(0deg 0% 100%) !important;
    border: 3px solid;
}

.scrollup:hover{
    border-color: hsl(0deg 0% 100%) !important;
    background-color: var(--first-color);
}

.scrollup__icon{
    font-size: 2rem;
    color: #FFF;
    justify-content: center;
    transform: translateY(3px);
}

/* Show scroll */


/*========== SCROLL BAR ==========*/
::-webkit-scrollbar{
    width: 0.6rem;
    background-color: var(--scroll-bar-color);
    border-radius: .5rem;
}

::-webkit-scrollbar-thumb{
    background-color: var(--scroll-thumb-color);
    border-radius: .5rem;
}

::-webkit-scrollbar-thumb:hover{
    background-color: var(--scroll-thumb-color-active);
}

/*==================== MEDIA QUERIES ====================*/
/* For small devices */
@media screen and (max-width: 350px){
    .container{
        margin-left: var(--mb-1);
        margin-right: var(--mb-1);
    }

    .nav__menu{
        padding: 2rem .25rem 4rem;
    }
    .nav__list{
        column-gap: 0;
    }

    .home__content{
        grid-template-columns: .25fr 3fr;
    }
    .home__blob{
        width: 180px;
    }

    .skills__titles,
    .qualification__title{
        font-size: var(--small-font-size);
    }
    .qualification__subtitle{
        font-size: var(--smaller-font-size);
    }
    .qualification__data{
        gap: .5rem;
    }
}

/* For medium devices */
@media screen and (min-width: 568px){
    .home__content{
        grid-template-columns: max-content 1fr 1fr;
    }
    .home__data{
        grid-column: initial;
    }
    .home__img{
        order: 1;
        justify-self: center;
    }

    .about__container,
    .skills__container,
    .contact__container,
    .footer__container{
        grid-template-columns: repeat(2, 1fr);
    }

    .qualification__sections{
        display: grid;
        grid-template-columns: .6fr;
        justify-content: center;
    }
}

@media screen and (min-width: 768px){
    .container{
        margin-left: auto;
        margin-right: auto;
    }

    body{
        margin: 0;
    }

    .section{
        padding: 6rem 0 2rem;
    }
    .section__subtitle{
        margin-bottom: 4rem;
    }
    .header{
        top: 0;
        bottom: initial;
    }
    .header,
    .main,
    .footer__container{
        padding: 0 1rem;
    }
    .nav{
        height: calc(var(--header-height) + 1.5rem);
        column-gap: 1rem;
    }
    .nav__icon,
    .nav__close,
    .nav__toggle{
        display: none;
    }
    .nav__list{
        display: flex;
        column-gap: 2rem;
    }
    .nav__menu{
        margin-left: auto;
    }
    .change-theme{
        margin: 0;
    }

    .home__container{
        row-gap: 5rem;
    }
    .home__content{
        padding-top: 5.5rem;
        column-gap: 2rem;
    }
    .home__blob{
        width: 270px;
    }
    .home__scroll{
        display: block;
    }
    .home__scroll-button{
        margin-left: 3rem;
    }

    .about__container{
        column-gap: 5rem;
    }
    .about__img{
        width: 350px;
    }
    .about__description{
        text-align: justify;
    }
    .about__info{
        justify-content: space-between;
    }
    .about__buttons{
        justify-content: initial;
    }

    .qualification__tabs{
        justify-content: center;
    }
    .qualification__button{
        margin: 0 var(--mb-1);
    }
    .qualification__sections{
        grid-template-columns: .5fr;
    }

    .portfolio__tabs{
        justify-content: center;
    }
    .portfolio__button{
        margin: 0 var(--mb-1);
    }

    .portfolio__subcat__tabs{
        justify-content: center;
    }
    .portfolio__subcat__button{
        margin: 0 var(--mb-1);
    }

    .footer__container{
        grid-template-columns: repeat(3, 1fr);
    }

    .footer__bg{
        padding: 3rem 0 3.5rem;
    }
}
.footer__links{
    flex-direction: row;
    column-gap: 2rem;
}
.footer__socials{
    justify-self: flex-end;
}
.footer__copy{
    margin-top: 4.5rem;
}

/* For large devices */
@media screen and (min-width: 1024px){
    .header,
    .main,
    .footer__container{
        padding: 0;
    }

    .home__blob{
        width: 320px;
    }
    .home__social{
        transform: translateX(-6rem);
    }
    .home__data{
        width: 400px        ;
        transform: translateX(-2rem);
    }

    .contact__form{
        width: 460px;
    }
    .contact__inputs{
        grid-template-columns: repeat(2, 1fr);
    }
}